<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
    <dl class="sm:divide-y sm:divide-gray-200">
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 gap-6">
        <dt class="text-sm font-medium text-gray-500">{{ title }}</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <p v-for="v in values" :key="v" class="flex justify-between">
            {{ v }}
            <a href="#" @click.prevent="click(v)" class="text-blue-400 ml-auto">
              Try
            </a>
          </p>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  props: ['values', 'title'],
  methods: {
    click(v) {
      this.$search.q = v;
      this.$search.search();
      this.$emit('close');
    },
  },
};
</script>

<style></style>
