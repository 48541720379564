<template>
  <div class="pb-8 flex-shrink-0">
    <div class="px-12 lg:px-0 drop-shadow-md">
      <!-- Search -->
      <div class="max-w-xs mx-auto w-full lg:max-w-md">
        <label for="search" class="sr-only">Search</label>
        <div class="relative text-white focus-within:text-gray-600">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
          >
            <!-- Heroicon name: solid/search -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <input
            id="search"
            class="block w-full text-white bg-white bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
            placeholder="Search"
            name="search"
            v-model="$search.q"
            autofocus
            @keyup.enter="$search.search"
            @focus="focused = true"
            @blur="focused = false"
            :disabled="$search.loading"
            autocomplete="off"
          />
          <div
            v-if="$search.loading"
            class="pointer-events-none absolute inset-y-0 right-0 pl-3 flex items-center"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              :class="{ 'text-white': !focused }"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <div
            v-else
            class="absolute inset-y-0 right-0 pl-3 flex items-center cursor-pointer"
            @click="showInfo = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="-ml-1 mr-3 h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
        <ACDropdown :data="$search.acResults" />
      </div>
    </div>
    <info-modal v-model="showInfo" />
  </div>
</template>

<script>
import InfoModal from './modals/info-modal.vue';
import ACDropdown from './ac-dropdown.vue';

export default {
  components: { InfoModal, ACDropdown },
  data() {
    return {
      focused: false,
      showInfo: false,
    };
  },
};
</script>
