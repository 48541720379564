<template>
  <div class="pt-8 flex-shrink-0">
    <div
      class="bg-white overflow-hidden drop-shadow-md rounded-lg flex px-6 py-5"
    >
      <!-- <div
        class="px-6 py-5 text-sm font-medium text-center cursor-pointer"
        @click="addGeoJSON"
      >
        Add GeoJSON
      </div> -->
      <div class="relative flex items-start">
        <div class="flex items-center h-5">
          <input
            id="candidates"
            aria-describedby="candidates-description"
            name="candidates"
            type="checkbox"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            v-model="$search.autocomplete"
          />
        </div>
        <div class="ml-3 text-sm">
          <label for="candidates" class="font-medium text-gray-700">
            Google Autocomplete
          </label>
        </div>
        <div class="flex items-center h-5 ml-3">
          <input
            id="geocode"
            aria-describedby="candidates-description"
            name="geocode"
            type="checkbox"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            v-model="$search.geocode"
          />
        </div>
        <div class="ml-3 text-sm">
          <label for="geocode" class="font-medium text-gray-700">
            Reverse geocode
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
