<template>
  <div
    class="bg-white overflow-hidden drop-shadow-md rounded-lg flex-grow flex flex-col"
  >
    <div class="overflow-y-auto h-full pt-2">
      <div class="p-4" v-if="json">
        <VueJsonPretty :data="$search.json || {}" />
      </div>
      <ul role="list" class="divide-y divide-gray-200" v-else>
        <Result
          v-for="result in $search.results"
          :key="result.place_id"
          v-bind="result"
        />
      </ul>
    </div>
    <div
      class="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x text-gray-600"
    >
      <div
        class="px-6 py-5 text-sm font-medium text-center"
        :class="{ 'text-blue-600 bg-blue-50': !json, 'cursor-pointer': json }"
        @click="json = false"
      >
        List
      </div>

      <div
        class="px-6 py-5 text-sm font-medium text-center"
        :class="{ 'text-blue-600 bg-blue-50': json, 'cursor-pointer': !json }"
        @click="json = true"
      >
        JSON
      </div>
    </div>
  </div>
</template>

<script>
import Result from './result.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  components: { Result, VueJsonPretty },
  data() {
    return { json: false };
  },
};
</script>

<style>
.vjs-tree {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  font-size: 12px;
}
</style>
