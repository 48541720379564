import { featureCollection, point } from '@turf/helpers';
import throttle from 'lodash.throttle';
import { API } from './utils/search.utils';

let instance;
export const getInstance = () => instance;

export const useSearch = (Vue) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        q: '',
        loading: false,
        results: [],
        autocomplete: false,
        geocode: false,
        acResults: [],
      };
    },
    methods: {
      async search() {
        try {
          this.loading = true;
          const data = await this.api.geocode({ address: this.q });

          this.setData(data);
        } finally {
          this.loading = false;
        }
      },
      async '/nomsql'() {
        const data = await this.api.getNomSql(this.q);
        this.results = data;
        this.$map.r = data;
      },
      async ac(v) {
        const data = await API.autocomplete(v);
        this.acResults = data;
      },
      async reverse(latlng) {
        try {
          this.loading = true;
          const data = await this.api.geocode({ latlng });

          this.setData({ results: data.results });
        } finally {
          this.loading = false;
        }
      },

      queryAutocomplete: throttle(
        function (v) {
          this.ac(v);
        },
        400,
        { leading: false },
      ),
      setData(data) {
        this.json = data;
        const results = data.results.map(({ location, ...r }) => ({
          ...r,
          geometry: featureCollection([point([location.lng, location.lat])]),
          id: r.place_id,
        }));
        this.results = results;
        this.$map.r = results;
      },
      async selectAC(place_id) {
        this.acResults = [];
        try {
          this.loading = true;
          const data = await this.api.geocode({ place_id });
          this.setData(data);
        } finally {
          this.loading = false;
        }
      },
    },
    created() {
      this.api = API;
    },
    watch: {
      q(v) {
        if (this.autocomplete) {
          this.queryAutocomplete(v);
        }
      },
    },
  });

  return instance;
};

export default {
  install(Vue) {
    Vue.prototype.$search = useSearch(Vue);
  },
};
