<template>
  <div class="h-screen bg-gradient-to-r from-sky-800 to-cyan-600 py-24">
    <div
      class="grid grid-cols-3 grid-rows-3 grid-flow-col gap-8 h-full max-h-full mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl relative"
    >
      <div class="row-span-3 col-span-2 flex flex-col">
        <Map />
        <Actions />
      </div>
      <div class="col-span-1 row-span-3 flex flex-col">
        <Search />

        <Results />
      </div>
    </div>
  </div>
</template>

<script>
import './index.css';
import Map from './components/map.vue';
import Search from './components/search.vue';
import Results from './components/results.vue';
import Actions from './components/actions.vue';

export default {
  name: 'App',
  components: { Map, Search, Results, Actions },
  mounted() {
    this.$map.init();
  },
};
</script>

<style></style>
