export const sampleQueries = [
  {
    title: 'POI search',
    values: [
      'parking near sam harris park',
      'walmart in sevierville',
      'warehouse on west cermak',
      'parks in kansas city',
      'schools near hobby hill park',
    ],
  },
  {
    title: 'Street intersection',
    values: [
      'laurel canyon and chandler blvd',
      'mccallum | coit',
      'mr marshall dr/progress hill blvd',
    ],
  },
  {
    title: 'Highway and street exits, overpass/underpass',
    values: [
      'i29 and tiffany springs',
      'us101 and kester',
      'us169 /belgrade ave',
    ],
  },
  {
    title: 'Highway interchange',
    values: ['us101 and i405', 'us101 / ca134'],
  },
];
