let instance;
import * as ol from 'ol';
export const getInstance = () => instance;
import * as proj from 'ol/proj';
import { Tile } from 'ol/layer';
import { OSM } from 'ol/source';
import {
  getOneVector,
  getPickupPointsVectors,
  getVector,
} from './utils/geojson.utils';
import bbox from '@turf/bbox';
import { featureCollection } from '@turf/helpers';

export const useMap = (Vue) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        zoom: 4,
        center: [-97.70507216453578, 41.882481173559626],
        rotation: 0,
        bounds: [
          -125.74218153953579, 17.827975872270386, -69.66796278953578,
          59.409196920496555,
        ],
        geometry: {},
        expand: null,
        r: [],
      };
    },
    methods: {
      init() {
        if (!this.map) {
          const map = new ol.Map({
            target: 'map',
            view: new ol.View({
              center: proj.fromLonLat(this.center),
              zoom: this.zoom,
            }),
            layers: [
              new Tile({
                source: new OSM({ attributions: false }),
              }),
            ],
          });

          this.map = map;

          this.map.on('click', (e) => {
            const feature = this.map.forEachFeatureAtPixel(e.pixel, (f) => {
              if (f.get('id')) {
                return f;
              }
            });

            if (feature) {
              const id = feature.get('id');
              this.expand = id;
            } else if (this.$search.geocode) {
              this.$search.reverse(proj.toLonLat(e.coordinate).reverse().join(','))
            }
          });
          this.map.on('pointermove', (e) => {
            var pixel = this.map.getEventPixel(e.originalEvent);
            const feature = this.map.forEachFeatureAtPixel(pixel, (f) => {
              if (f.get('id')) {
                return f;
              }
            });

            this.map.getViewport().style.cursor = feature ? 'pointer' : '';
          });
        }
      },
      fitBounds(bounds, convert) {
        if (bounds) {
          this.map
            .getView()
            .fit(
              convert
                ? proj.transformExtent(bounds, 'EPSG:4326', 'EPSG:3857')
                : bounds,
              {
                padding: [200, 200, 200, 200],
                maxZoom: 16,
              },
            );
        }
      },
      renderGeometry(v) {
        const vector = getVector(v);
        this.map.addLayer(vector);
      },
      getLayer() {
        let layer;
        this.$map.map.getLayers().forEach((l) => {
          if (l.get('name') === 'GEOJSON') {
            layer = l;
          }
        });
        return layer;
      },
      getLayerById(id) {
        let la;
        this.map.getLayers().forEach((l) => {
          if (l.get('name')?.indexOf(id) >= 0) {
            la = l;
          }
        });
        return la;
      },
      getFeature(id) {
        const geometry = this.getLayer()?.getSource().getFeatureById(id);
        return geometry;
      },
      focusFeature(id) {
        this.$map.fitBounds(this.getLayerById(id)?.getSource().getExtent());
      },
      highlightFeature(id) {
        const l = this.getLayerById(id);
        l?.getSource()
          ?.getFeatures()
          ?.forEach((v) => v.set('highlight', true));
      },
      unhighlightFeature(id) {
        const l = this.getLayerById(id);
        l?.getSource()
          ?.getFeatures()
          ?.forEach((v) => v.set('highlight', false));
      },
      removeLayers() {
        this.map.getLayers().forEach((v) => {
          const id = v?.get('name');
          if (id && id.startsWith('d_')) {
            this.map.removeLayer(v);
          }
        });
      },
      addPickupPoints({ poi }) {
        getPickupPointsVectors(poi);
      },
    },
    watch: {
      geometry(v) {
        if (v.features && v.features.length) {
          if (v?.features?.length) {
            this.removeLayers();
            this.renderGeometry(v);
            this.fitBounds(bbox(v), true);
          }
        }
      },
      r(v) {
        const vectors = v.map(getOneVector);
        this.removeLayers();
        vectors.forEach((b) => {
          this.map.addLayer(b);
        });

        this.fitBounds(
          bbox(
            featureCollection(
              v.map(({ geometry }) => geometry.features).flat(),
            ),
          ),
          true,
        );
      },
    },
  });

  return instance;
};

export default {
  install(Vue) {
    Vue.prototype.$map = useMap(Vue);
  },
};
