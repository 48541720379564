import { featureCollection, point } from '@turf/helpers';
import axios from 'axios';
import throttle from 'lodash.throttle';
import * as R from 'ramda';

const baseURL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';
const authorization =
  'TGFib3JlIGVzc2Ugc2ludCBub3N0cnVkIHZvbHVwdGF0ZSBhbmltIGVsaXQuIENpbGx1bSB2b2x1cHRhdGUgY29uc2VxdWF0IHJlcHJlaGVuZGVyaXQgZXhjZXB0ZXVyIGV4IGVpdXNtb2QgaXBzdW0gZWl1c21vZCBleGNlcHRldXIgZXN0IGluY2lkaWR1bnQgZXggYWRpcGlzaWNpbmcgYW5pbS4gRXN0IGNvbW1vZG8gYXV0ZSBleCBzdW50IG51bGxhLiBDb25zZWN0ZXR1ciB0ZW1wb3IgZWEgaXBzdW0gZXNzZSBkZXNlcnVudCBhZGlwaXNpY2luZyBleGVyY2l0YXRpb24gcXVpIG5vbiBsYWJvcmlzIGN1bHBhIGV4ZXJjaXRhdGlvbiBhZGlwaXNpY2luZyBjb25zZXF1YXQuIEFkIGVhIGNvbW1vZG8gbm9zdHJ1ZCBub24gZXNzZS4gRnVnaWF0IGluIGVpdXNtb2QgZG8gZXhlcmNpdGF0aW9uIG1hZ25hLiBFbGl0IHN1bnQgY2lsbHVtIGFuaW0gZXNzZSBjdWxwYSBub24gZWEgbWFnbmEgbGFib3JlLg==';

export const API = axios.create({ baseURL, headers: { authorization } });

API.getSearch = async (q) => {
  const { data } = await API.get('/search', {
    params: { q },
  });
  return data;
};

API.getInfo = async (id, type) => {
  const { data } = await API.get('/overpass/info', { params: { id, type } });
  return data;
};

API.getDetails = async (id) => {
  const { data } = await API.get('/nominatim/details', { params: { id } });
  return data;
};

API.getOverpassQuery = async (q) => {
  const { data } = await API.post(
    '/overpass/query',
    { q },
    { params: { geojson: true } },
  );
  return data;
};
API.getGoogleMapsResults = async (address) => {
  const { data } = await API.get('/google-maps/geocode', {
    params: { address, geojson: true },
  });
  return data;
};

API.getNomSql = async (q) => {
  const { data } = await API.get('/nom-sql/q', { params: { q, json: true } });
  return data;
};

API.geocode = async (params) => {
  const { data } = await API.get('/geocode', {
    params: { ...params, json: true },
  });

  return data;
};

API.autocomplete = async (input) => {
  const { data } = await API.get('/autocomplete', { params: { input } });
  return data.predictions || [];
};

API.getSearchByPlaceId = async (place_id) => {
  const { data } = await API.get('/search', {
    params: { place_id },
  });
  return data;
};
