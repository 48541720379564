<template>
  <div class="py-3 px-2" v-if="items">
    <div class="text-sm text-yellow-800 mb-2">{{ title }}</div>
    <div class="flex">
      <div class="mr-2">
        <div class="text-xs text-slate-500" v-for="(i, k) in items" :key="k">
          <span>{{ k }}</span>
        </div>
      </div>
      <div>
        <div class="text-xs text-slate-800" v-for="(i, k) in items" :key="k">
          <span>{{ i }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'items'],
};
</script>
