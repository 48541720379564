import Vue from 'vue';
import App from './App.vue';
import store from './store';
import MapPlugin from './map.plugin';
import SearchPlugin from './search.plugin';

Vue.config.productionTip = false;
Vue.use(MapPlugin);
Vue.use(SearchPlugin);

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
