import GeoJSON from 'ol/format/GeoJSON';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Layer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';

const image = new CircleStyle({
  radius: 5,
  fill: new Fill({ color: '#1d4ed8' }),
  stroke: new Stroke({
    color: '#1d4ed8',
    width: 1,
  }),
});

const imageHighlight = new CircleStyle({
  radius: 10,
  fill: new Fill({ color: '#b91c1c' }),
  stroke: new Stroke({
    color: '#b91c1c',
    width: 2,
  }),
});

const styles = {
  Point: new Style({
    image: image,
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: '#3b82f6',
      width: 4,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: '#3b82f6',
      width: 4,
    }),
  }),
  MultiPoint: new Style({
    image: image,
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: '#3b82f6',
      width: 4,
    }),
  }),
};

const stylesHighlight = {
  Point: new Style({
    image: imageHighlight,
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: '#b91c1c',
      width: 8,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: '#b91c1c',
      width: 8,
    }),
  }),
  MultiPoint: new Style({
    image: imageHighlight,
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: '#b91c1c',
      width: 8,
    }),
  }),
};

const styleFunction = function (feature) {
  if (feature.get('highlight')) {
    return stylesHighlight[feature.getGeometry().getType()];
  }
  return styles[feature.getGeometry().getType()];
};

export const getVector = (v) => {
  const features = new GeoJSON().readFeatures(v, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  });
  const vectorSource = new VectorSource({
    features,
  });

  const vectorLayer = new VectorLayer({
    name: 'GEOJSON_',
    source: vectorSource,
    style: styleFunction,
  });
  vectorLayer.set('name', 'GEOJSON');

  return vectorLayer;
};

export const getOneVector = ({ geometry, id }) => {
  const features = new GeoJSON().readFeatures(geometry, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  });
  const vectorSource = new VectorSource({
    features,
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });
  vectorLayer.set('name', `d_${id}`);

  return vectorLayer;
};

export const getPickupPointsVectors = (v) => {
  console.log(
    '%cgeojson.utils.js line:123 { geometry, id }',
    'color: #007acc;',
    v,
    // { geometry, id },
  );
  // const features = new GeoJSON().readFeatures(geometry, {
  //   dataProjection: 'EPSG:4326',
  //   featureProjection: 'EPSG:3857',
  // });
  // const vectorSource = new VectorSource({
  //   features,
  // });

  // const vectorLayer = new VectorLayer({
  //   source: vectorSource,
  //   style: styleFunction,
  // });
  // vectorLayer.set('name', `d_${id}`);

  // return vectorLayer;
};
