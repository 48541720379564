<template>
  <ul
    class="mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm border"
    tabindex="-1"
    role="listbox"
    aria-labelledby="listbox-label"
    aria-activedescendant="listbox-option-3"
    v-if="data && data.length"
  >
    <!--
        Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
      -->
    <li
      class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-indigo-800 cursor-pointer"
      v-for="item in data"
      :key="item.place_id"
      @click="$search.selectAC(item.place_id)"
    >
      <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
      <span class="font-normal block truncate"> {{ item.description }} </span>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['data'],
};
</script>
