<template>
  <li
    class="relative hover:bg-gray-50"
    @mouseenter="$map.highlightFeature(id)"
    @mouseleave="$map.unhighlightFeature(id)"
  >
    <div
      class="absolute top-1 left-1 opacity-10 hover:opacity-100"
      @click="$search.q = name"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>
    <div class="divide-y divide-gray-200 gap-2 px-4 sm:px-6">
      <a href="#" class="block" @click.prevent="$map.focusFeature(id)">
        <div class="py-4">
          <div class="flex items-start justify-between">
            <p class="text-sm font-medium text-indigo-600">
              {{ name }}
            </p>
            <p class="flex items-center text-xs text-gray-400">
              {{ direction }}
            </p>
          </div>
          <div class="mt-2 sm:flex sm:justify-between items-start">
            <div class="sm:flex gap-2">
              <p class="text-xs text-gray-700">
                {{ formatted_address }}
              </p>
              <p class="flex items-center text-xs text-gray-700">
                {{ description }}
              </p>
            </div>
            <div v-if="types" class="flex absolute right-1 top-1 max-w-full">
              <div
                class="ml-2 flex-shrink-0 flex flex-wrap"
                v-for="type in types.slice(-2)"
                :key="type"
              >
                <p
                  class="px-2 inline-flex text-[10px] leading-3 font-semibold rounded-full bg-green-100 text-green-800 uppercase"
                >
                  {{ type }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </a>
      <template v-if="expanded">
        <ResultDetails title="Address" :items="address" />
        <ResultDetails title="Name details" :items="names" />
        <ResultDetails title="Tags" :items="tags" />
        <ResultDetails title="Types" :items="types" />
      </template>
    </div>
    <div
      class="absolute right-1 bottom-1 text-gray-400 hover:text-gray-700"
      @click="expanded = !expanded"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        :class="{ 'rotate-180': expanded }"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </li>
</template>

<script>
import ResultDetails from './result-details.vue';

export default {
  components: { ResultDetails },
  props: [
    'name',
    'types',
    'description',
    'direction',
    'address',
    'names',
    'tags',
    'formatted_address',
    'id',
  ],
  data() {
    return { expanded: false };
  },
  watch: {
    '$map.expand'(id) {
      if (id === this.id) {
        this.expandSelf();
      }
    },
  },
  methods: {
    expandSelf() {
      this.expanded = true;
      this.$el.scrollIntoView();
    },
  },
};
</script>

<style></style>
